<template>
  <!-- 提现列表 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="商户">
          <el-input v-model="searchData.merchantName" size="small" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="时间" class="item">
          <el-date-picker
            style="width:280px;"
            v-model="searchData.timeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchData.status" placeholder="请选择" size="small" clearable>
            <el-option v-for="(item, i) in dict_merchant_withdraw_status" :key="i" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="handle-btn">
      <div></div>
      <el-button v-checkbtn="isShowBtn(AUTH_BTN.merchant_withdraw_list_export)" type="primary" icon="el-icon-download" size="small" @click="handleExport"
        >导出</el-button
      >
    </div>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { DownloadAPI, getListAPI } from './api'
import { dict_merchant_withdraw_status } from '@/enum/dict.js'
import { authBtnMixin } from '@/mixins/authBtnMixin.js'
const columns = [
  {
    label: '时间',
    prop: 'createTime',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '提现金额',
    prop: 'withdrawAmount',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['withdrawAmount']}</p>
    }
  },

  {
    label: '操作人',
    prop: 'applyUser',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['applyUser']?.['userName']}</p>
    }
  },
  {
    label: '提现状态',
    prop: 'status',
    minWidth: '120',
    customRender(h, row) {
      return (
        <p>
          <el-tag type={this.filterType(row['status'])} size="medium">
            {this.filterStatus(row['status'])}
          </el-tag>
        </p>
      )
    }
  },
  {
    label: '商户',
    prop: 'merchantName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['merchantName']}</p>
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        merchantName: '',
        timeSlot: [],
        status: ''
      },
      dict_merchant_withdraw_status
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { timeSlot, merchantName, status } = this.searchData
      let params = { page: this.currentPage, pageSize: this.pageSize, merchantName, status }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleExport() {
      const { timeSlot, merchantName, status } = this.searchData
      let params = { merchantName, status }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      DownloadAPI(params)
    },
    filterStatus(status) {
      let name = ''
      const res = this.dict_merchant_withdraw_status.find(item => item.value == status)
      if (res) {
        name = res.name
      }
      return name
    },
    filterType(status) {
      let type = 'primary'
      if (status == 10) {
        type = 'primary'
      } else if (status == 20) {
        type = 'warning'
      } else if (status == 21) {
        type = 'success'
      } else if (status == 22) {
        type = 'danger'
      }
      return type
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .el-divider--horizontal {
    margin: 0 0 20px;
  }

  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 15px;
        .el-select,
        .el-input {
          width: 280px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 30%;
          }
        }
      }
      .keywords {
        .el-select .el-input {
          width: 100px;
        }

        .el-select {
          width: 100px;
        }
        .input-with-select {
          vertical-align: middle;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
        }
      }
    }
  }

  .handle-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
